import { navigate } from "gatsby-link"
import * as React from "react"
interface Props {
  location: { search: string }
}

const FlottenUpload = (props: Props) => {
  React.useEffect(() => {
    navigate("/kundenbereich/start")
  }, [])
  return <>Bitte warten</>
}

export default FlottenUpload
